<template>
  <div class="about" >

    <div class="about-layer1">
      <div style="text-align: center;background: #395772;">
        <img class="about-img" id="about-img" src="@/assets/images/avatar_pro.png" width="50%" style="padding-top:20px;background-color: #395772"  >
      </div>
      <p style="padding: 15px;
      color:#ffffff;
      background: #395772;
      border-top-right-radius: 35px;
      border-top-left-radius: 35px;
      margin: 0;
      padding-bottom: 50px;">
        ارتباط با مدیر
      </p>
      <div class="about-layer2">
        <p>
          <v-icon class="vIcon">mdi-account-question</v-icon>
          <a target="_blank" href="tg://resolve?domain=neshatjavan" class="alink"> تلگرام مدیر: neshatjavan@ </a>
        </p>
        <!--<p>
          <v-icon class="vIcon">mdi-phone</v-icon>
          <a href="whatsapp://send?text=سلام&phone=+989377697283" class="alink"> واتس آپ مدیر : کلیک کنید </a>
        </p>-->
        <p>
          <v-icon class="vIcon">mdi-account-child-circle</v-icon>
          <a target="_blank" href="tg://resolve?domain=pishbin90chanel" class="alink"> کانال اصلی برنامه : pishbin90chanel@ </a>
        </p>
        <!--<p>
          <v-icon class="vIcon">mdi-package-down</v-icon>
          <a target="_blank" href="https://goftino.com/c/CK3wGK" class="alink"> ارتباط با مدیر داخلی : کلیک کنید </a>
        </p>
        <p>
          <v-icon class="vIcon">mdi-kitesurfing</v-icon>
          <a target="_blank" href="https://p-90.blogfa.com" class="alink"> وبلاگ مسابقات : کلیک کنید </a>
        </p>-->
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
  import img from '@/assets/images/avatar_pro.png'
  import appHeader from "./_header";
  import appFooter from "./_footer";

  export default {
    name: 'App',
    components: {
      appFooter

    },
    data(){
      return{
        setting:{},
      }
    },
    mounted() {
      //this.getSetting();
      //document.querySelector('about-img').attribute('src',img);
    },
    methods:{
      getSetting(){
        let self = this;
        this.helperSetting().then(function (r) {
          self.setting = r;
        })
      }
    }
  }
</script>

<style>
  .about-layer1{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;
    left: 0;
    background: #395772;

  }

  .about-layer2{
    padding: 25px 20px;
    background: #fff;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    margin-top: -35px;
  }

  .vIcon{
    background: #a0d1ffa6;
    border-radius: 5px;
    padding: 7px;
    color: #1976d2!important;
  }
  .alink{
    text-decoration: none;
    color: black !important;
  }
</style>
